import React, { useEffect, useState } from "react";
import "../assets/css/BlogDetail.css";
import Navbar from "./Home/Navbar";
import Banner2 from "./Home/Banner2";
import Footer from "./Home/Footer";
import { Link } from "react-router-dom";

import WebService, { urls } from "../Services/WebService";
import Spinner from "./Home/Spinner";

const AllBlogs = () => {
  const [loading, setLoading] = useState(true);
  const [blogData, setBlogData] = useState([]);
  const [latestBlog, setLatestBlog] = useState([]);
  const [banner, setBanner] = useState("");

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await WebService.GetApiCall(urls.HOMEAPI);
        if (response.data.success) {
          const home = response.data.home_page;
          setBanner(home.banner_img);
          setBlogData(home.blog);
          setLatestBlog(home.blog_section[0]);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error loading data:", error);
        setLoading(false);
      }
    };
    loadData();
  }, []);
  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        <section>
          <Banner2 text={"All Blogs"} header={"All Blogs"} banner={banner} />
        </section>

        <section>
          <div className="container-box pt-5" id="ALL-BLOG">
            <div className="py-md-5">
              <div className="ps-4 ps-xs-0">
                <h2 className="mb-4">{latestBlog.blog_title}</h2>
                <p className="text-muted">{latestBlog.blog_description}</p>
              </div>

              {/* Our All Blog Posts */}

              <div className="row ps-4 ps-xs-0 blog-row">
                {blogData.map((ob) => {
                  const renderFirst15Words = (content) => {
                    const first15Words = content
                      .split(" ")
                      .slice(0, 14)
                      .join(" ");
                    return (
                      <span
                        dangerouslySetInnerHTML={{ __html: first15Words }}
                      />
                    );
                  };
                  return (
                    <div className="col-sm-4 mb-5" key={ob.id}>
                      <div className="service-card blog-card shadow-sm">
                        <div>
                          <Link
                            to={`/glamor-decor/blog/${ob.slug}`}
                            className="w-100"
                          >
                            <img src={ob.main_img} className="w-100" alt="" />

                            <div className="hover-box">{ob.postDate}</div>
                          </Link>
                        </div>

                        <div className="service-card-content mt-4">
                          <Link to={`/blog/${ob.slug}`} className="text-dark">
                            <p>{ob.title}</p>
                          </Link>
                          <p className="text-muted">
                            {renderFirst15Words(ob.content)}
                          </p>

                          <Link to={`/blog/${ob.slug}`}>
                            {" "}
                            Read Full Blog{" "}
                            <i className="bi bi-chevron-double-right arrow-animation"></i>{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AllBlogs;
