import React, { useState } from "react";
import "../../assets/css/Portfolio.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import Banner2 from "./Banner2";
import WebService, { urls } from "../../Services/WebService";
import { useEffect } from "react";
import Spinner from "./Spinner";
import Swal from "sweetalert";

const Portfolio = () => {
  const [banner, setBanner] = useState("");
  const [loading, setLoading] = useState(true);
  const [portData, setPortData] = useState([]);
  const [checkoutWork, setcheCkoutWork] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [ctaSection, setCtaSection] = useState([]);
  const [service, setService] = useState("Coating");

  // Form Integration
  const [numericValue, setNumericValue] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const loadData = async () => {
      const response = await WebService.GetApiCall(urls.PORTFOLIO);
      if (response.data.success) {
        setLoading(false);
        const PortFolio = response.data?.capabilities_page;
        setPortData(PortFolio.capabilities[0]);
        const Bannerimg = PortFolio.capabilities[0].banner_img;
        setBanner(Bannerimg);
        setcheCkoutWork([PortFolio.capabilities_section[0]]);
        setTabData(PortFolio.capabilities_video);
        setCtaSection([PortFolio.portfilio_cta[0]]);
      }
    };
    loadData();
  }, []);

  const navigate = useNavigate();
  const handleNumericChange = (event) => {
    // Remove non-numeric characters using a regular expression
    const newValue = event.target.value.replace(/[^0-9+]/g, "");
    setNumericValue(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", numericValue);
    formData.append("message", message);
    try {
      const response = await WebService.postApiCall(urls.CONTACT, formData);
      if (response) {
        setLoading(false);
        // Swal Popup
        // Swal({
        //   icon: "success",
        //   text: "Your message send successfully",
        // });
        setNumericValue("");
        setName("");
        setEmail("");
        setMessage("");
        navigate("/thankyou");
      }
    } catch (error) {
      setLoading(false);
      Swal({
        icon: "error",
        text: "Something Went Wrong",
      });
    }
  };

  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        <section>
          <Banner2
            text={"Our Capabilities"}
            header={"Our Portfolio"}
            banner={banner}
          />
        </section>

        <section>
          <div className="py-5 ">
            <div className="container-box py-md-5">
              <div className="row">
                <div className="col-md-6 pr-0 pfmb">
                  <div
                    className="w-100 h-100 "
                    style={{
                      backgroundImage: `url(${portData.blog_img})`,
                      backgroundSize: "cover",
                      backgroundPosition: "50% 50%",
                    }}
                    data-aos="fade-right"
                    data-aos-delay="100"
                  ></div>
                </div>

                <div className="col-md-6 pl-0 pfmbspace">
                  <div style={{ backgroundColor: "#efefef" }}>
                    <div className="p-md-5 p-3 ">
                      <p className="text-uppercase">Portfolio</p>

                      <h2
                        className="head-2 mb-4"
                        data-aos="fade-out"
                        data-aos-delay="100"
                      >
                        {portData.cap_heading}
                      </h2>

                      <p className="paragraph-2">
                        <i className="bi bi-arrow-right me-2"></i>
                        {portData.cap_subtitle}
                      </p>

                      <p className="paragraph-2">
                        <i className="bi bi-arrow-right me-2"></i>
                        {portData.cap_subtitle2}
                      </p>
                      <p className="paragraph-2">
                        <i className="bi bi-arrow-right me-2"></i>
                        {portData.cap_subtitle3}
                      </p>
                      <p className="paragraph-2">
                        <i className="bi bi-arrow-right me-2"></i>
                        {portData.cap_subtitle4}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div>
            <div className="container-box pb-md-5">
              <div className="row">
                {checkoutWork.map((ob, i) => {
                  return (
                    <div key={i + 2}>
                      <h2
                        className="head-2 "
                        data-aos="fade-out"
                        data-aos-delay="100"
                      >
                        {ob.capb_title}
                      </h2>
                      <p className="col-5 mobile-width">
                        {ob.capb_description}
                      </p>
                    </div>
                  );
                })}
                <div className="d-flex">
                  <ul className="bd-service-tab nav nav-tabs" id="portpli">
                    <li className="nav-item">
                      <Link
                        onClick={() => {
                          setService("Coating");
                          const element = document.getElementById("img");
                          element.classList.remove("bd-portfolio-img-1");
                          void element.offsetWidth;
                          element.classList.add("bd-portfolio-img-1");
                        }}
                        className={
                          service === "Coating"
                            ? "nav-link active shadow-sm"
                            : "nav-link"
                        }
                      >
                        Coating
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={() => {
                          setService("Printing");
                          const element = document.getElementById("img");
                          element.classList.remove("bd-portfolio-img-1");
                          void element.offsetWidth; // trigger reflow
                          element.classList.add("bd-portfolio-img-1");
                        }}
                        className={
                          service === "Printing"
                            ? "nav-link active shadow-sm"
                            : "nav-link"
                        }
                      >
                        Printing{" "}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={() => {
                          setService("Foiling");
                          const element = document.getElementById("img");
                          element.classList.remove("bd-portfolio-img-1");
                          void element.offsetWidth; // trigger reflow
                          element.classList.add("bd-portfolio-img-1");
                        }}
                        className={
                          service === "Foiling"
                            ? "nav-link active shadow-sm"
                            : "nav-link"
                        }
                      >
                        Foiling{" "}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={() => {
                          setService("Frosting");
                          const element = document.getElementById("img");
                          element.classList.remove("bd-portfolio-img-1");
                          void element.offsetWidth; // trigger reflow
                          element.classList.add("bd-portfolio-img-1");
                        }}
                        className={
                          service === "Frosting"
                            ? "nav-link active shadow-sm"
                            : "nav-link"
                        }
                      >
                        Frosting{" "}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={() => {
                          setService("Decal Printing");
                          const element = document.getElementById("img");
                          element.classList.remove("bd-portfolio-img-1");
                          void element.offsetWidth; // trigger reflow
                          element.classList.add("bd-portfolio-img-1");
                        }}
                        className={
                          service === "Decal Printing"
                            ? "nav-link active shadow-sm"
                            : "nav-link"
                        }
                      >
                        Decal Printing{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="row bd-images-row mt-5" id="bd-portfolio-fix">
                    {tabData.map((obj, i) => {
                      if (obj.service_name === service) {
                        return (
                          <div
                            className="col-6 py-2 px-2 bd-portfolio-img d-flex align-items-center justify-content-center p-0 m-0"
                            key={i + 2}
                          >
                            <div className="Port-card-1  w-100">
                              <img
                                src={obj.img}
                                className="bd-portfolio-img-1 w-100 object-fit-cover"
                                id="img"
                                data-aos="fade-right"
                                data-aos-delay="100"
                                style={{ height: "500px" }}
                                alt="images"
                              ></img>
                              <div className="pt-md-4 port-data"></div>
                            </div>
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          {ctaSection.map((ob, i) => {
            return (
              <div
                key={i + 2}
                className="py-5 contact-bg"
                style={{
                  backgroundImage: `url(${ob.background_img})`,
                }}
              >
                <div className="container-box py-md-5">
                  <div className="row justify-space-between">
                    <div className="col-md-7 ps-md-5">
                      <div className="cta-section">
                        <h2>{ob.portfoliocta_text}</h2>
                        <p>{ob.portfoliocta_subtitle}</p>
                        <div className="pt-5 ">
                          <Link className="style-btn style-btn-3 webview">
                            <span style={{ paddingLeft: "18px" }}>
                              <i className="bi bi-telephone "></i>
                            </span>{" "}
                            <div className="d-inline">
                              Call Us : {ob.portfoliocta_button_text}
                            </div>
                          </Link>
                          <div className="cta-long-btn">
                            <div className="mb-5">
                              <Link className="style-btn style-btn-3">
                                <span style={{ paddingLeft: "18px" }}>
                                  <i className="bi bi-telephone "></i>
                                </span>{" "}
                                <div className="d-inline">
                                  Call Us :{" "}
                                  {ob.portfoliocta_button_text.slice(0, 14)}
                                </div>
                              </Link>
                            </div>
                            <div className="mb-5">
                              <Link className="style-btn style-btn-3 ">
                                <span style={{ paddingLeft: "18px" }}>
                                  <i className="bi bi-telephone "></i>
                                </span>{" "}
                                <div className="d-inline">
                                  Call Us :{" "}
                                  {ob.portfoliocta_button_text.slice(15)}
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <form className="bd-cta-form " onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <div className="bd-forms-inputs">
                              <input
                                placeholder="Type Your Name"
                                type="text"
                                value={name}
                                required
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="bd-forms-inputs">
                              <input
                                placeholder="Type Your Email Address"
                                type="email"
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="bd-forms-inputs">
                              <input
                                placeholder="Type Your Mobile Number"
                                type="text"
                                value={numericValue}
                                minLength={10}
                                maxLength={10}
                                required
                                onChange={handleNumericChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="bd-forms-inputs">
                              <textarea
                                placeholder="Type Your Message"
                                type="text"
                                rows="3"
                                required
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <button className="submit-contact">
                            Send Message
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Portfolio;
