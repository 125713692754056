import React, { useEffect, useState } from "react";
import logo from "../../assets/Images/white-logo.png";
import logo2 from "../../assets/Images/logo.png";
import { Link, useLocation } from "react-router-dom";
import WebService, { urls } from "../../Services/WebService";

const Navbar = () => {
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [shopCate, setShopCate] = useState([]);
  const location = useLocation();

  const services = [
    { ob: "/coating" },
    { ob: "/printing" },
    { ob: "/foiling" },
    { ob: "/frosting" },
    { ob: "/decal-print" },
  ];
  const shopbyproduct = [
    { ob: "/glass-bottles/products" },
    { ob: "/glass-bottles/products" },
  ];
  const shopbyproductpath = shopbyproduct.find(
    (item) => item.ob === location.pathname
  );
  const findpath = services.find((item) => item.ob === location.pathname);
  // For Navlink color end code
  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  });

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  function openNav() {
    document.getElementById("mySidenav").style.width = "100%";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  function OpenService() {
    document.getElementById("service").style.height = "100%";
    document.getElementById("service").style.visibility = "visible";
  }
  function OpenProduct() {
    document.getElementById("Product").style.height = "100%";
    document.getElementById("Product").style.visibility = "visible";
  }

  useEffect(() => {
    const loadData = async () => {
      const response = await WebService.GetApiCall(urls.CATEGORIESDROP);
      if (response.data.success) {
        setShopCate(response.data.data);
      }
    };
    loadData();
  }, []);
  return (
    <div>
      <nav className={`pd-navbar ${stickyNavbar ? "skicky-navbar" : ""}`}>
        <div className="container-box">
          <div className="row justify-space-between align-items-center ">
            <div className="col-2 text-md-start text-center">
              <Link className="navbar-brand" to="/" aria-label="home">
                <img
                  src={stickyNavbar ? logo2 : logo}
                  alt="Logo"
                  width="160"
                  style={{ height: "100%" }}
                ></img>
              </Link>
            </div>
            <div
              className="col-md-10 text-md-end text-center d-md-block d-none"
              // style={{ width: "75vw" }}
            >
              <div className="navbar-links ">
                <ul className="navlinks">
                  <li>
                    <Link
                      aria-label="home"
                      to="/"
                      className={
                        location.pathname === "/"
                          ? "home-link active-link"
                          : "home-link"
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "white" }}
                      id="DropDown2"
                      className={
                        findpath ? "home-link active-link" : "home-link"
                      }
                    >
                      Our Services{" "}
                      <i className="bi bi-caret-down-fill ps-1"></i>{" "}
                    </Link>
                    <ul className="inner-links text-start">
                      <li>
                        <Link to="/coating">Coating</Link>
                      </li>
                      <li>
                        <Link to="/printing">Printing</Link>
                      </li>
                      <li>
                        <Link to="/foiling">Foiling</Link>
                      </li>
                      <li>
                        <Link to="/frosting">Frosting</Link>
                      </li>
                      <li>
                        <Link to="/decal-print">Decal Print</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      style={{ color: "white" }}
                      id="DropDown"
                      className={
                        shopbyproductpath
                          ? "home-link active-link"
                          : "home-link"
                      }
                    >
                      Shop By Product
                      <i className="bi bi-caret-down-fill ps-1"></i>{" "}
                    </Link>
                    <ul className="inner-links text-start">
                      {shopCate.map((ob) => {
                        return (
                          <li key={ob.id}>
                            <Link to={`/category/${ob.slug}`}>{ob.name}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li>
                    <Link
                      to="/about-us"
                      className={
                        location.pathname === "/about-us" ? " active-link" : ""
                      }
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/portfolio"
                      className={
                        location.pathname === "/portfolio" ? " active-link" : ""
                      }
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/career"
                      className={
                        location.pathname === "/career" ? " active-link" : ""
                      }
                    >
                      Career
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      className={
                        location.pathname === "/contact-us"
                          ? " active-link"
                          : ""
                      }
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/enquiry"
                      className={
                        location.pathname === "/enquiry" ? " active-link" : ""
                      }
                    >
                      {" "}
                      <i className="bi bi-info-circle me-2"></i>Enquiry
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-2 bd-nav-mobile">
              <Link
                className="bd-menu-list"
                aria-label="home"
                onClick={() => openNav()}
              >
                <i className="bi bi-list"></i>
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div id="mySidenav" className="sidenav">
        <Link
          // to="javascript:void(0)"
          className="closebtn"
          onClick={() => closeNav()}
        >
          &times;
        </Link>
        <Link aria-label="home" to="/">
          <span>01.</span> Home
        </Link>
        <Link onClick={() => OpenService()} style={{ cursor: "pointer" }}>
          <span>02.</span> Services
          <ul className="bd-sub-services" id="service">
            <li>
              <Link to={"/coating"}>
                <span>01.</span> Coating
              </Link>
            </li>
            <li>
              <Link to={"/printing"}>
                <span>02.</span> Printing
              </Link>
            </li>
            <li>
              <Link to={"/foiling"}>
                <span>03.</span> Foiling
              </Link>
            </li>
            <li>
              <Link to={"/frosting"}>
                <span>04.</span> Frosting
              </Link>
            </li>
            <li>
              <Link to={"/decal-print"}>
                <span>05.</span> Decal Print
              </Link>
            </li>
          </ul>
        </Link>

        {/* Add New Pages  */}
        <Link onClick={() => OpenProduct()} style={{ cursor: "pointer" }}>
          <span>02.</span> Shop By Product
          <ul className="bd-sub-services" id="Product">
            {shopCate.map((ob) => {
              return (
                <li key={ob.id}>
                  <Link to={`/category/${ob.slug}`}>{ob.name}</Link>
                </li>
              );
            })}
          </ul>
        </Link>
        {/* ................ */}
        <Link to={"/about-us"}>
          <span>03.</span> About
        </Link>
        <Link to={"/portfolio"}>
          <span>04.</span> Portfolio
        </Link>
        <Link to={"/career"}>
          <span>05.</span> Career
        </Link>
        <Link to={"/contact-us"}>
          <span>06.</span> Contact Us
        </Link>
        <Link to={"/enquiry"}>
          <span>07.</span> Enquiry
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
