import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Banner2 from "./Banner2";
import WebService, { urls } from "../../Services/WebService";
import Spinner from "./Spinner";
import Swal from "sweetalert";
import { useLocation, useNavigate } from "react-router";

const Enquiry = () => {
  // form
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [numericValue, setNumericValue] = useState("");

  // Form end
  const [loading, setLoading] = useState(true);
  const [enquiryData, setEnquiryData] = useState([]);
  const [banner, setBanner] = useState("");

  const location = useLocation();

  const handleNumericChange = (event) => {
    // Remove non-numeric characters using a regular expression
    const newValue = event.target.value.replace(/[^0-9+]/g, "");
    setNumericValue(newValue);
  };

  const loadData = async () => {
    const response = await WebService.GetApiCall(urls.ENQUIRY);

    if (response) {
      setLoading(false);
      const enquiry = response.data.data;

      setEnquiryData(enquiry);
      setBanner(enquiry.banner_img);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const navigate = useNavigate();

  // Form
  const handleForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("email", email);
    formData.append("phone", numericValue);
    formData.append("company_name", company);
    formData.append("address", address);
    formData.append("product_pack", service);
    formData.append("message", message);
    try {
      const response = await WebService.postApiCall(urls.ENQUIRYMAIL, formData);
      if (response) {
        setLoading(false);
        // Swal Popup
        // Swal({
        //   icon: "success",
        //   text: "Your message send successfully",
        // });

        setFname("");
        setLname("");
        setEmail("");
        setNumericValue("");
        setCompany("");
        setAddress("");
        setService("");
        setMessage("");
        navigate("/thankyou");
      }
    } catch (error) {
      setLoading(false);
      Swal({
        icon: "error",
        text: "Something Went Wrong",
      });
    }
  };
  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        {/* Banner */}
        <section>
          <Banner2 text={""} header={"Enquiry Now"} banner={banner} />
        </section>

        <section>
          <div className="py-5">
            <div className="py-md-5">
              <div>
                <div className="row position-relative align-items-center justify-content-end">
                  <div
                    className="col-3 pl-0  bd-get-info mobile-enquiry"
                    style={{ left: "77px" }}
                  >
                    <img
                      src={enquiryData.enquiry_img}
                      alt="No img"
                      className="w-100"
                      height="750"
                    />
                  </div>
                  <div className="col-9 pr-0 mobile-width">
                    <div
                      className="bd-contact-from"
                      style={{
                        padding: "80px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <div className="col-md-10">
                        <div>
                          <h1 className="head-2 ">
                            IF YOU HAVE QUESTIONS Enquiry
                          </h1>
                          <p>Fill fields and find approximate your repair</p>
                        </div>
                        <div>
                          {/* form */}

                          <form onSubmit={handleForm}>
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <div className="bd-forms-inputs">
                                  <input
                                    placeholder="Type Your First Name"
                                    type="text"
                                    value={location.state?.name}
                                    onChange={(e) => setFname(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div className="bd-forms-inputs">
                                  <input
                                    required
                                    placeholder="Type Your Last Name"
                                    type="text"
                                    value={lname}
                                    onChange={(e) => setLname(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div className="bd-forms-inputs">
                                  <input
                                    placeholder="Type Your Email Address"
                                    type="email"
                                    value={location.state?.email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div className="bd-forms-inputs">
                                  <input
                                    minLength={10}
                                    maxLength={10}
                                    placeholder="Type Your Phone Number"
                                    type="text"
                                    onChange={handleNumericChange}
                                    value={numericValue}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="bd-forms-inputs">
                                  <input
                                    required
                                    placeholder="Type Your Company Name"
                                    type="text"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="bd-forms-inputs">
                                  <textarea
                                    required
                                    placeholder="Type Your Address"
                                    type="text"
                                    rows="4"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="bd-forms-inputs">
                                  <select
                                    required
                                    value={service}
                                    onChange={(e) => setService(e.target.value)}
                                  >
                                    <option value="">
                                      Select Your service
                                    </option>
                                    <option>Printing</option>
                                    <option>Coating</option>
                                    <option>Foiling</option>
                                    <option>Frosting</option>
                                    <option>Decal Print</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="bd-forms-inputs">
                                  <textarea
                                    required
                                    placeholder="Type Your Message"
                                    type="text"
                                    rows="7"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <button className="submit-contact">
                                Send Message
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Enquiry;
