import React from "react";
import "../../assets/css/Spinner.css";

const Spinner = () => {
  return (
    <div>
      <div className="loader">
        <div className="loader-inner ball-pulse-sync">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
