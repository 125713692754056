import React, { useEffect, useRef, useState, useMemo } from "react";
import Slider from "react-slick";

// slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "aos/dist/aos.css";

import "../../assets/css/Home.css";
import WebService, { urls } from "../../Services/WebService";

const CarouselSlider = (props) => {
  const [slidIndex, setSlidIndex] = useState(0);
  const [slider, setSlider] = useState([]);

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    beforeChange: (current, next) => setSlidIndex(next),
  };

  const sliderRef = useRef();
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await WebService.GetApiCall(urls.BANNER);
        if (response.data.success) {
          setSlider(response.data.banner);
        }
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };
    loadData();
  }, []);

  const sliderdata = useMemo(() => slider, [slider]);

  return (
    <>
      <div
        className="position-relative"
        style={{ width: "100vw", height: "100vh" }}
        id="vh-xs-auto"
      >
        <Slider ref={sliderRef} {...settings}>
          {sliderdata.map((ob, i) => {
            return (
              <div
                key={ob.id + i}
                className={
                  i === 0
                    ? `carousel-slide-middle carousel-slide`
                    : i === 2
                    ? `calausel-last carousel-slide`
                    : i === 1
                    ? `carousel-slide`
                    : ``
                }
              >
                <img src={ob.img} alt="Design Is Our Identity" />
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default CarouselSlider;
