import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import "../../../assets/css/ProductBottle.css";
import { Link, useParams } from "react-router-dom";
import Footer from "../Footer";
import Banner2 from "../Banner2";
import WebService, { urls } from "../../../Services/WebService";
import Spinner from "../Spinner";

const ProductBottle = () => {
  const [loading, setLoading] = useState(true);
  const [productCate, setProductCate] = useState([]);
  const [productData, setProductData] = useState([]);
  const [bannerImg, setBannerImg] = useState("");

  const slug = useParams();
  const loadData = async () => {
    const formData = new FormData();
    formData.append("slug", slug.slug);
    const response = await WebService.postApiCall(urls.PRODUCTS, formData);
    if (response) {
      setLoading(false);

      setProductCate(response.data.category);
      setProductData(response.data.products);
      setBannerImg(response.data.category.banner_img);
    }
  };

  useEffect(() => {
    loadData();
  }, [slug]);

  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        <section>
          <Banner2
            text={"Shop By Product"}
            header={productCate.name}
            banner={bannerImg}
          />
        </section>

        {/* Discription */}
        <section style={{ backgroundColor: "#efefef" }}>
          <div className="container py-5">
            <div className=" py-3">
              <h2 className="head-2 mb-4">{productCate.name}</h2>
              <p className="paragraph-2 ">
                {" "}
                <span
                  dangerouslySetInnerHTML={{ __html: productCate.description }}
                />
              </p>
            </div>
            <div className="row cards">
              {productData.map((ob) => {
                return (
                  <div
                    key={ob.id}
                    className="col-sm-4 col-md-3"
                    id="prodBottle"
                  >
                    <div>
                      <Link to={`/product/${ob.slug}`}>
                        <div className="service-card-1 mt-4 shadow-lg card">
                          <img src={ob.image} className="service-img" alt="" />
                          <div className="pt-md-4 service-data ">
                            <span>Know More</span>
                            <i className="bi bi-chevron-double-right arrow-animation"></i>{" "}
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="mt-3 m-0">
                      <Link
                        to={`/product/${ob.slug}`}
                        className="nav-link productName text-center px-3 text-uppercase"
                      >
                        {ob.title}
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ProductBottle;
