import React, { useState } from "react";
import Navbar from "./Navbar";
import "../../assets/css/Contact.css";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import Banner2 from "./Banner2";
import WebService, { urls } from "../../Services/WebService";
import { useEffect } from "react";
import Spinner from "./Spinner";
import Swal from "sweetalert";

const ContactUs = () => {
  const [loading, setLoading] = useState(true);
  const [contactData, setContactData] = useState([]);
  const [numericValue, setNumericValue] = useState("");
  const [bannerImg, setBannerImg] = useState("");
  const [number, setNumber] = useState("");

  const handleNumericChange = (event) => {
    // Remove non-numeric characters using a regular expression
    const newValue = event.target.value.replace(/[^0-9+]/g, "");
    setNumericValue(newValue);
  };

  // Form Integration
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", numericValue);
    formData.append("message", message);
    try {
      const response = await WebService.postApiCall(urls.CONTACT, formData);
      if (response) {
        setLoading(false);
        // Swal Popup
        // Swal({
        //   icon: "success",
        //   text: "Your message send successfully",
        // });
        setName("");
        setEmail("");
        setMessage("");
        setNumericValue("");
        navigate("/thankyou");
      }
    } catch (error) {
      setLoading(false);
      Swal({
        icon: "error",
        text: "Something Went Wrong",
      });
    }
  };

  // getapi code
  const loadData = async () => {
    const response = await WebService.GetApiCall(urls.CONTACTGET);
    if (response.data.success) {
      setLoading(false);
      setContactData(response.data.contact_page.contact_page[0]);

      const banner = response.data.contact_page.contact_page[0].banner_img;
      setNumber(response.data.contact_page.contact_page[0].contact_numbers);
      setBannerImg(banner);
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        {/* Banner */}
        <section>
          <Banner2 text={""} header={"Contact Us"} banner={bannerImg} />
        </section>
        <section>
          <div className="py-5">
            <div className="py-md-5">
              <div>
                <div className="row position-relative align-items-center">
                  <div className="col-9 pr-0 mobile-width">
                    <div className="bd-contact-from ">
                      <div className="col-md-10">
                        <div>
                          <h1 className="head-2 ">
                            IF YOU HAVE QUESTIONS PLEASE CONTACT US{" "}
                          </h1>
                          <p>Fill fields and find approximate your repair</p>
                        </div>
                        <div>
                          <form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <div className="bd-forms-inputs">
                                  <input
                                    placeholder="Type Your Name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div className="bd-forms-inputs">
                                  <input
                                    placeholder="Type Your Email Address"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div className="bd-forms-inputs">
                                  <input
                                    value={numericValue}
                                    onChange={handleNumericChange}
                                    minLength={10}
                                    maxLength={10}
                                    placeholder="Type Your Mobile Number"
                                    type="text"
                                    pattern="[0-9]*"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="bd-forms-inputs">
                                  <textarea
                                    placeholder="Type Your Message"
                                    type="text"
                                    rows="7"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <button className="submit-contact">
                                Send Message
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 pl-0 bd-get-info">
                    <div>
                      <div className="info-details">
                        <div className="d-flex mb-3">
                          <i className="bi bi-globe-americas"></i>
                          <div>
                            <p>Website</p>
                            <p>
                              <Link to="https://www.glamourdecor.in">
                                {contactData.contact_form_weblink}
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex mb-3">
                          <i className="bi bi-geo-alt"></i>
                          <div>
                            <p>Head Office:</p>
                            <p>{contactData.contact_addresses}</p>
                          </div>
                        </div>
                        <div className="d-flex mb-3">
                          <i className="bi bi-telephone-inbound"></i>
                          <div>
                            <p>Phones</p>
                            <p>
                              <Link to="callto:+91 9952186877">
                                {number.split(',')[0]}
                              </Link>
                              <br />
                              <Link to="callto:+91 9384370697 ">
                                {number.split(',')[1]}{" "}
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex mb-3">
                          <i className="bi bi-envelope-at"></i>
                          <div>
                            <p>Write Us</p>
                            <p className="mb-0">
                              <Link to="mailto:mahesh@glamourdecor.in">
                                {contactData?.contact_mails?.split(',')[0]}
                              </Link>
                            </p>
                            <p className="mt-1">
                            <Link to="mailto:mahesh@glamourdecor.in">
                                {contactData?.contact_mails?.split(',')[1]}
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div className="bd-top-border">
                          <p className="mb-4"> FOLLOW US </p>

                          <div>
                            <Link to={contactData.facebook_link}>
                              <i className="bi bi-facebook"></i>
                            </Link>
                            <Link to={contactData.twitter_link}>
                              <i className="bi bi-instagram"></i>
                            </Link>
                            <Link to={contactData.youtube_link}>
                              <i className="bi bi-youtube"></i>
                            </Link>
                            <Link to={contactData.linkedin_link}>
                              <i
                                className="bi bi-linkedin"
                                style={{ marginRight: "0px" }}
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ContactUs;
