import React from "react";
import Navbar from "../Home/Navbar";
import Banner from "../Home/Banner";
import Footer from "../Home/Footer";
import { Link, useNavigate } from "react-router-dom";

import "../../assets/css/Service.css";
import WebService, { urls } from "../../Services/WebService";
import { useState } from "react";
import { useEffect } from "react";
import Spinner from "../Home/Spinner";

const Coating = () => {
  const [loading, setLoading] = useState(true);
  const [coatingService, setCoatingService] = useState([]);
  const [otherService, setOtherService] = useState([]);
  const [bannerImg, setBannerImg] = useState("");
  const [backgroundImg, setBackgroundImg] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/enquiry", {
      state: {
        name: name,
        email: email,
      },
    });
  };

  const loadData = async () => {
    const response = await WebService.GetApiCall(urls.OURSERVICES);

    if (response.data.success) {
      setLoading(false);
      const CoatingService = response.data.service_page.list_of_service[0];
      const OtherService = response.data.service_page.related_product;
      setBannerImg(response.data.service_page.sintro_section[0].banner_img);
      setBackgroundImg(
        response.data.service_page.ourservice_section[0].background_img
      );
      setOtherService(OtherService);

      setCoatingService(CoatingService);
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  const danghtml = (content) => {
    return <span dangerouslySetInnerHTML={{ __html: content }} />;
  };
  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        <section>
          <Banner
            text={"Our Service"}
            header={"Coating Services"}
            banner={bannerImg}
          />
        </section>
        {/* Blog */}

        <section>
          <div className="py-5">
            <div className="container-box py-md-5">
              <div className="row">
                <div className="col-sm-12 col-md-6 servicepimg pr-0 ">
                  <div
                    className="w-100 h-100 "
                    style={{
                      backgroundImage: `url("${coatingService.img}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "50% 50%",
                    }}
                    data-aos="fade-right"
                    data-aos-delay="100"
                  ></div>
                </div>

                <div className="col-sm-12 col-md-6 pl-0 sppadding">
                  <div
                    style={{
                      backgroundColor: "#efefef",
                      borderBottomRightRadius: "30px",
                    }}
                    className="shadow"
                  >
                    <div className="p-md-5 p-3 ">
                      <h2
                        className="head-2 mb-4"
                        data-aos="fade-out"
                        data-aos-delay="100"
                      >
                        {coatingService.title}
                      </h2>
                      <p className="paragraph-2">
                        {danghtml(coatingService.description)}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section>
          <div
            className="py-5  contact-bg"
            style={{ backgroundImage: `url(${backgroundImg})` }}
          >
            <div className="container-box py-md-5">
              <div className="row">
                <div className="col-md-8 ps-md-5">
                  <div className="cta-section">
                    <h2>{coatingService.cta_title}</h2>
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex mobile-flex-wrap">
                        <div className="cta-input">
                          <i className="bi bi-person"></i>
                          <input
                            type="text"
                            placeholder="Name"
                            required
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="cta-input">
                          <i className="bi bi-envelope"></i>
                          <input
                            type="email"
                            placeholder="Email Address"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <button
                          className="btn style-btn style-btn-3 text-white"
                          to="/enquiry"
                        >
                          <span>{" > "}</span>{" "}
                          <div className="d-inline">
                            {coatingService.cta_btn}
                          </div>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}

        <section>
          <div className="py-5 bg-light-gray overflow-hidden">
            <div className="container-box py-md-4 ">
              <div className="d-md-flex justify-content-between align-items-center">
                <div className="top-heading">
                  <span className="title-h2 title-block-lg d-block heading-color">
                    <span>
                      Our Other <br />
                      <b>AWESOME Services </b>
                    </span>
                  </span>
                </div>

                <div className="mt-md-0 mt-5">
                  <Link to="/printing" className="style-btn">
                    <span>{" > "}</span>{" "}
                    <div className="d-inline">view other services</div>
                  </Link>
                </div>
              </div>

              <div className="mt-5">
                <div className="row our-services justify-content-between">
                  {otherService
                    .filter((element, index) => index !== 0)
                    .map((ob) => {
                      return (
                        <div key={ob.id} className="col-md-3 ">
                          <div className="service-card-1 mt-4">
                            <img src={ob.img} className="service-img" alt="" />
                            <p className="bd-service-head h4">
                              {ob.name} service
                            </p>
                            <div className="pt-md-4 service-data ">
                              <Link
                                to={
                                  ob.name === "Decal Print"
                                    ? `/decal-print`
                                    : `/${ob.name.toLowerCase()}`
                                }
                              >
                                {" "}
                                Know More{" "}
                                <i className="bi bi-chevron-double-right arrow-animation"></i>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Coating;
