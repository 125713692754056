import React, { useRef, useState } from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Footer from "./Footer";

const WhatMakeDifferent = () => {
  const [slidIndex, setSlidIndex] = useState(0);
  const sliderRef = useRef();

  const serviceSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setSlidIndex(next),
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleOnClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div>
      <Navbar />
      <div className="position-relative">
        <div
          className=" banner-slider"
          // style={{
          //   backgroundImage: `url(${banner1})`,
          //   backgroundPosition: "top",
          // }}
        >
          <div
            className="container-box padding-banner "
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <div className="text-white banner-text text-right">
              <h1 className="fw-bold display-4 text-capitalize">
                What Makes Us Different
              </h1>
              <div className="d-flex justify-content-end">
                <p className="col-5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  porttitor dictum lobortis. Vestibulum sit amet elementum
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 bg-light-gray bg-dots">
        <div className="container-box py-md-5">
          <div className="px-md-5">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <i className="bi bi-dash-lg fs-2"></i>
                </div>
                <p className="fs-4 text-uppercase letter-spacing mb-4 ">
                  One Stop Service
                </p>
                <p className="paragraph-2 ">
                  Glamour Décor offers “One stop Solution “for Glass bottle
                  surface decoration for customized packaging & brand identity.
                </p>
              </div>

              <div className="col-md-6">
                <div>
                  <i className="bi bi-dash-lg fs-2"></i>
                </div>
                <p className="fs-4 text-uppercase letter-spacing mb-4 ">
                  Quality Assurance
                </p>
                <p className="paragraph-2 ">
                  Glamour Décor follows strict quality measures to ensure high
                  end quality products to clients.
                </p>
              </div>

              <div className="col-md-6 mt-4">
                <div>
                  <i className="bi bi-dash-lg fs-2"></i>
                </div>
                <p className="fs-4 text-uppercase letter-spacing mb-4 ">
                  Design & Development
                </p>
                <p className="paragraph-2 ">
                  Glamour Décor designing team transform your personal design
                  inspiration in to a reality with effective & efficient manner.
                </p>
              </div>

              <div className="col-md-6 mt-4">
                <div>
                  <i className="bi bi-dash-lg fs-2"></i>
                </div>
                <p className="fs-4 text-uppercase letter-spacing mb-4 ">
                  Supply Network
                </p>
                <p className="paragraph-2 ">
                  With quality service level & stable supply we are able to
                  create perfect environment of faith & loyalty for our valued
                  clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light-gray bg-dots">
        <div className="container-box py-md-5">
          <div className="px-md-5">
            <div className="d-md-flex justify-content-between align-items-center">
              <div className="heading-top">
                <h3>
                  Unique Bottle and Packaging Decorations for
                  <strong> Unique Brands</strong>
                </h3>
              </div>
            </div>

            <div className="row mt-5 pt-4 justify-content-between align-items-center">
              <div className="col-md-6 pe-md-4">
                <p className="fs-4 text-uppercase letter-spacing mb-4 pb-2">
                  Quality Assurance
                </p>

                <p className="paragraph-2">
                  At Glamour decor we always think safety first quality always.
                </p>

                <p className="paragraph-2">
                  Our strict quality program starts with our digital proofing in
                  the very early stage of your packaging development and is
                  maintained long after your bottles have been delivered to you.
                </p>
                <p className="paragraph-2">
                  We think quality as a global foundation of our philosophy in
                  our processes and all departments. If you have special quality
                  requirements it is probably already part of our procedure and
                  we trained and equipped to control it.
                </p>
                <p className="paragraph-2">
                  Contact us to find out more about our quality.
                </p>
                <div className="mt-5">
                  <Link className="style-btn">
                    <span>{" > "}</span>{" "}
                    <div className="d-inline">Contact Us</div>
                  </Link>
                </div>
              </div>

              <div className="col-md-6 position-relative ps-md-4">
                <Slider
                  ref={sliderRef}
                  {...serviceSettings}
                  className="border border-5 p-2 pb-1 mb-0 lh-0"
                >
                  <div className="col-md-5">
                    <div className="service-card work-card service-slider mx-0">
                      <div>
                        {/* <img src={banner4} className="w-100" alt="" /> */}

                        <div className="work-card-content service-hover-card">
                          <p>
                            Coating provides touch of stylishness & peculiarity
                            to Glass bottle surface. Coating not only amplify
                            the visual appeal of the product but also prevents
                            scratches formation during manufacture handling,
                            cleaning and filling of bottles.
                          </p>
                          <h3>Coating Design</h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="service-card work-card service-slider mx-0">
                      <div>
                        {/* <img src={banner3} className="w-100" alt="" /> */}

                        <div className="work-card-content service-hover-card">
                          <p>
                            Printing on a Glass bottle is a popular decoration
                            technique which permit us to add lively colours,
                            unique design and branding characteristic to your
                            Glass bottle Surface. but also provide brand
                            divergence and elegant appeal to glass bottle
                            surface.
                          </p>
                          <h3>Printing Design</h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="service-card work-card service-slider mx-0">
                      <div>
                        {/* <img src={foiling} className="w-100" alt="" /> */}

                        <div className="work-card-content service-hover-card">
                          <p>
                            Foiling is an application of metallic or holographic
                            foil on glass bottle surface. This decoration
                            technique brings luxurious and eye-catching effect,
                            perfect for luxury products or special editions.
                            Foiling can be apply to give touch of
                            sophistication.
                          </p>
                          <h3>Foiling Design</h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="service-card work-card service-slider mx-0">
                      <div>
                        {/* <img src={frosting} className="w-100" alt="" /> */}

                        <div className="work-card-content service-hover-card">
                          <p>
                            Frosting, in other term known as etching, frosting
                            gives your glass bottles matte look. Thistechnique
                            involves applying a fine layer of acid or
                            sandblasting to the surface of the glass, resulting
                            ina smooth and elegant finish.
                          </p>
                          <h3>Frosting Design</h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="service-card work-card service-slider mx-0">
                      <div>
                        {/* <img src={banner1} className="w-100" alt="" /> */}

                        <div className="work-card-content service-hover-card">
                          <p>
                            Decal Decoration includes application of customized
                            or pre-printed pattern on a Glass bottle surface.
                            Designs are made on thin adhesive film or PVC vinyl.
                            This method is used for complex design & artwork.
                            Decal print is customized option to print logo,
                            illustration or any viewable elements that match to
                            your brand character.{" "}
                          </p>
                          <h3>Decal Print Design</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>

                <div className="slider-buttons">
                  <button
                    onClick={(e) => handleOnClick(e.target.value)}
                    value={0}
                    className={`${slidIndex === 0 && "activeSlide"}`}
                    aria-label="slide button 1"
                  ></button>

                  <button
                    onClick={(e) => handleOnClick(e.target.value)}
                    value={1}
                    className={`${slidIndex === 1 && "activeSlide"}`}
                    aria-label="slide button 2"
                  ></button>

                  <button
                    onClick={(e) => handleOnClick(e.target.value)}
                    value={2}
                    className={`${slidIndex === 2 && "activeSlide"}`}
                    aria-label="slide button 3"
                  ></button>
                  <button
                    onClick={(e) => handleOnClick(e.target.value)}
                    value={3}
                    className={`${slidIndex === 3 && "activeSlide"}`}
                    aria-label="slide button 4"
                  ></button>

                  <button
                    onClick={(e) => handleOnClick(e.target.value)}
                    value={4}
                    className={`${slidIndex === 4 && "activeSlide"}`}
                    aria-label="slide button 5"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-5  contact-bg">
        <div className="container-box py-md-5">
          <div className="row justify-space-between">
            <div className="col-md-7 ps-md-5">
              <div className="cta-section">
                <h2>
                  Search for your quality Glass bottle decoration work finished
                  here…
                </h2>
                <p>
                  Glamour Décor ensures customized solutions for your Glass
                  bottle product to magnify visual appeal & branding character.
                </p>
                <div className="pt-5">
                  <Link className="style-btn style-btn-3">
                    <span style={{ paddingLeft: "18px" }}>
                      <i className="bi bi-telephone "></i>
                    </span>{" "}
                    <div className="d-inline">
                      Call Us : +91 9054914347 | +91 7486008505
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <form className="bd-cta-form ">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="bd-forms-inputs">
                      <input placeholder="Type Your Name" type="text" />
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="bd-forms-inputs">
                      <input
                        placeholder="Type Your Email Address"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="bd-forms-inputs">
                      <input
                        placeholder="Type Your Mobile Number"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="bd-forms-inputs">
                      <textarea
                        placeholder="Type Your Message"
                        type="text"
                        rows="3"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button className="submit-contact">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WhatMakeDifferent;
