import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import "../../../assets/css/ProductBottle.css";
import Footer from "../Footer";
import { Link, useParams } from "react-router-dom";
import Banner2 from "../Banner2";
import WebService, { urls } from "../../../Services/WebService";
import Spinner from "../Spinner";

const Product = (props) => {
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  const [bannerImg, setBannerImg] = useState("");
  const slug = useParams();
  const loadData = async () => {
    const formData = new FormData();
    formData.append("slug", slug.slug);
    const response = await WebService.postApiCall(urls.GETPRODUCT, formData);
    if (response) {
      console.log(response);
      setLoading(false);
      setProductData(response.data.data);
      setBannerImg(response.data.data.banner_img);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        {/* Banner Start */}

        <section>
          <Banner2
            text={"Shop By Product"}
            header={"Glass Bottles"}
            banner={bannerImg}
          />
        </section>

        {/* Banner end */}
        <section>
          <div className="py-5" id="prod">
            <div className="container py-5" id="Productp">
              <div
                className="row shadow"
                style={{ borderBottomRightRadius: "20px" }}
              >
                <div className="col-lg-6 p-0 prodmainimg">
                  <div
                    className="w-100 h-100 "
                    style={{
                      backgroundImage: `url("${productData.image}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "50% 50%",
                    }}
                    data-aos="fade-right"
                    data-aos-delay="100"
                  ></div>
                </div>
                <div className="col-lg-6 px-5 padding-mb">
                  <div>
                    <p className="productName pt-4 ps-1 ">
                      {" "}
                      {productData.title}
                    </p>
                  </div>

                  <div className="decpription ps-1">
                    <h3>Description</h3>
                  </div>

                  <div className="mx-3">
                    <div className="row border py-4">
                      <div className="col-7 px-5 padding-mb">
                        <div className="d-flex justify-content-between">
                          <p>OFC(ml)</p>
                          <span>:</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p>Height(mm)</p>
                          <span>:</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p>Dimensions(mm)</p>
                          <span>:</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="">Glass Type</p>
                          <span>:</span>
                        </div>{" "}
                        <div className="d-flex justify-content-between">
                          <p className="">Neck Finish</p>
                          <span>:</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="m-0">Weight (gm)</p>
                          <span>:</span>
                        </div>
                      </div>

                      <div className="col-5">
                        <p>{productData.ofc ? productData.ofc : "Null"}</p>
                        <p>
                          {productData.height ? productData.height : "Null"}
                        </p>
                        <p>
                          {productData.dimension
                            ? productData.dimension
                            : "Null"}{" "}
                        </p>
                        <p>
                          {productData.glass_type
                            ? productData.glass_type
                            : "Null"}{" "}
                        </p>
                        <p>
                          {productData.neck_finish
                            ? productData.neck_finish
                            : "Null"}{" "}
                        </p>
                        <p className="m-0">
                          {productData.weight ? productData.weight : "Null"}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="py-3">
                      <p
                        className="text-muted p-0"
                        style={{ textAlign: "justify" }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: productData.description,
                          }}
                        ></span>
                        {/* {productData.description} */}
                      </p>
                    </div>
                    <div className="text-center pb-3">
                      <Link to="/enquiry" className="nav-link">
                        <button className="btn btn-success w-75 py-2">
                          <i className="bi bi-info-circle me-2"></i>
                          <span className="fs-4">ENQUIRY</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Product;
