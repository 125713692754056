import React from "react";
import Navbar from "./Navbar";
import "../../assets/css/About.css";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Banner2 from "./Banner2";
import WebService, { urls } from "../../Services/WebService";
import { useEffect } from "react";
import { useState } from "react";
import Spinner from "./Spinner";
import Swal from "sweetalert";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);
  const [aboutData, setAboutData] = useState([]);
  const [bannerImg, setBannerImg] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  // Form Integration
  const [numericValue, setNumericValue] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const loadData = async () => {
    const response = await WebService.GetApiCall(urls.ABOUTUS);

    if (response.data.success) {
      setLoading(false);
      const About = response.data.about_page;
      const ContactNumber = About.introduce_section[0].cta_section_button_text;
      setContactNumber(ContactNumber);
      setAboutData([About]);
      const Bannerimg = About.about_cta[0].banner_img;
      setBannerImg(Bannerimg);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  const handleNumericChange = (event) => {
    // Remove non-numeric characters using a regular expression
    const newValue = event.target.value.replace(/[^0-9+]/g, "");
    setNumericValue(newValue);
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", numericValue);
    formData.append("message", message);
    try {
      const response = await WebService.postApiCall(urls.CONTACT, formData);
      if (response) {
        setLoading(false);
        // Swal Popup
        // Swal({
        //   icon: "success",
        //   text: "Your message send successfully",
        // });
        setName("");
        setEmail("");
        setMessage("");
        setNumericValue("");
        navigate("/thankyou");
      }
    } catch (error) {
      setLoading(false);
      Swal({
        icon: "error",
        text: "Something Went Wrong",
      });
    }
  };

  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        {/* Banner */}
        <section>
          <Banner2
            text={"Our Company"}
            header={"About Us"}
            banner={bannerImg}
          />
        </section>
        <section>
          {aboutData.map((ob, i) => {
            const content = ob.introduce_section[0].introduce_description;
            const title = ob.introduce_section[0].introduce_title;
            const vision = ob.mission_vision[0].mission_description2;
            const mission = ob.mission_vision[0].mission_description;
            const danghtml = (content) => {
              return <span dangerouslySetInnerHTML={{ __html: content }} />;
            };
            return (
              <div key={i + 2}>
                <div className="py-5">
                  <div className="container-box py-md-5">
                    <div className="row about-section px-md-5">
                      <div className="col-md-6">
                        <div>
                          <div className="heading-top">
                            <h4
                              style={{
                                fontSize: "26px",
                                textTransform: "uppercase",
                              }}
                            >
                              {title}
                            </h4>
                          </div>

                          <div className="mt-4">
                            {/* accordian */}
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    <i className="bi bi-lightbulb me-3"></i>{" "}
                                    {ob.mission_vision[0].mission_heading2}
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse show"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <p className="paragraph-2 pe-md-5">
                                      {danghtml(vision)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    <i className="bi bi-trophy me-3"></i>
                                    {ob.mission_vision[0].mission_heading}
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <p className="paragraph-2 pe-md-5">
                                      {danghtml(mission)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 ps-md-5">
                        <div>
                          <p className="paragraph-2"> {danghtml(content)} </p>
                          <div className="mt-4 pb-1">
                            <h6 className="sm-heading fs-5 letter-spacing mb-1">
                              GLAMOUR DECOR
                            </h6>
                            <p className="paragraph-2">
                              {" "}
                              Design and Decoration
                            </p>
                          </div>

                          <div className="mt-4 px-4 py-5 pe-0 border d-md-flex align-items-end shadow">
                            <span
                              className="bordered-text d-inline-block"
                              style={{ transform: "scaleY(1.25)" }}
                            >
                              {ob.introduce_section[0].partner_section_text}
                            </span>
                            <p
                              className="fs-4 text-uppercase letter-spacing mb-0 ms-3 border-start ps-3"
                              style={{ transform: "translateY(20px)" }}
                            >
                              {ob.introduce_section[0].partner_section_title}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="py-5  contact-bg"
                  style={{
                    backgroundImage: `url(${ob.introduce_section[0].background_img})`,
                  }}
                >
                  <div className="container-box py-md-5">
                    <div className="row justify-space-between">
                      <div className="col-md-7 ps-md-5">
                        <div className="cta-section">
                          <h2>{ob.about_cta[0].aboutcta_title}</h2>
                          <p>{ob.about_cta[0].aboutcounter}</p>
                          <div className="pt-5 ">
                            <Link className="style-btn style-btn-3 webview">
                              <span style={{ paddingLeft: "18px" }}>
                                <i className="bi bi-telephone "></i>
                              </span>{" "}
                              <div className="d-inline px-2">
                                {
                                  ob.introduce_section[0]
                                    .cta_section_button_text
                                }
                              </div>
                            </Link>
                            <div className="cta-long-btn">
                              <div className="mb-5">
                                <Link className="style-btn style-btn-3">
                                  <span style={{ paddingLeft: "18px" }}>
                                    <i className="bi bi-telephone "></i>
                                  </span>{" "}
                                  <div className="d-inline">
                                    Call Us : {contactNumber.slice(0, 14)}
                                  </div>
                                </Link>
                              </div>
                              <div className="mb-5">
                                <Link className="style-btn style-btn-3 ">
                                  <span style={{ paddingLeft: "18px" }}>
                                    <i className="bi bi-telephone "></i>
                                  </span>{" "}
                                  <div className="d-inline">
                                    Call Us : {contactNumber.slice(16)}
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        {/* form */}

                        <form className="bd-cta-form " onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="bd-forms-inputs">
                                <input
                                  required
                                  placeholder="Type Your Name"
                                  type="text"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="bd-forms-inputs">
                                <input
                                  placeholder="Type Your Email Address"
                                  type="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="bd-forms-inputs">
                                <input
                                  required
                                  placeholder="Type Your Mobile Number"
                                  type="text"
                                  minLength={10}
                                  maxLength={10}
                                  value={numericValue}
                                  onChange={handleNumericChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="bd-forms-inputs">
                                <textarea
                                  required
                                  placeholder="Type Your Message"
                                  type="text"
                                  rows="3"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <button className="submit-contact">
                              Send Message
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AboutUs;
