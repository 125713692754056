import React, { useEffect, useState } from "react";
import Navbar from "../Home/Navbar";
import Banner from "../Home/Banner";
import Footer from "../Home/Footer";
import Spinner from "../Home/Spinner";
import WebService, { urls } from "../../Services/WebService";

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);
  const [privacyData, setPrivacyData] = useState([]);
  const [bannerImg, setBannerImg] = useState("");

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await WebService.GetApiCall(urls.PRIVACYPOLICY);
        if (response.data.success) {
          setLoading(false);
          setPrivacyData(response.data.privacy_policy[0]);
          setBannerImg(response.data.privacy_policy[0].banner_img);
        }
      } catch (error) {
        console.log(error);
      }
    };
    loadData();
  }, []);
  const danghtml = (content) => {
    return <span dangerouslySetInnerHTML={{ __html: content }} />;
  };
  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        <section>
          <Banner
            text={"Privacy Policy"}
            header={"Privacy Policy"}
            banner={bannerImg}
          />
        </section>
        <section>
          <div className="py-5">
            <div className="container-box py-md-5 mx-4">
              <div className="mb-5">
                <h2 className=" display-5 fw-semibold ">Privacy Policy</h2>
              </div>
              <div id="privacy-content">
                <p className="paragraph-2 " style={{ textAlign: "justify" }}>
                  {danghtml(privacyData.privacy_policy_text)}
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
