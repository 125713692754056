import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useState } from "react";
import Coating from "./Components/Services/Coating";
import Printing from "./Components/Services/Printing";
import Foiling from "./Components/Services/Foiling";
import Frosting from "./Components/Services/Frosting";
import DecalPrint from "./Components/Services/DecalPrint";
import ContactUs from "./Components/Home/ContactUs";
import AboutUs from "./Components/Home/AboutUs";
import Enquiry from "./Components/Home/Enquiry";
import Career from "./Components/Home/Career";
import Portfolio from "./Components/Home/Portfolio";
import WhatMakeDifferent from "./Components/Home/WhatMakeDifferent";
import ProductBottle from "./Components/Home/Product/ProductBottle";
import Product from "./Components/Home/Product/Product";
import HomeMain from "./Components/Home/HomeMain";
import BlogDetails from "./Components/BlogDetails";
import AllBlogs from "./Components/AllBlogs";
import ThankYou from "./Components/ThankYou/ThankYou";
import PrivacyPolicy from "./Components/Privacy-Policy/PrivacyPolicy";

function App() {
  const [stickySocial, setStickySocial] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 300) {
      setStickySocial(true);
    } else {
      setStickySocial(false);
    }
  });

  return (
    <div className="">
      <Router>
        <Routes>
          <Route exact path="/" Component={HomeMain} />
          <Route path="/coating" Component={Coating} />
          <Route path="/printing" Component={Printing} />
          <Route path="/foiling" Component={Foiling} />
          <Route path="/frosting" Component={Frosting} />
          <Route path="/decal-print" Component={DecalPrint} />
          <Route path="/contact-us" Component={ContactUs} />
          <Route path="/about-us" Component={AboutUs} />
          <Route path="/enquiry" Component={Enquiry} />
          <Route path="/career" Component={Career} />
          <Route path="/portfolio" Component={Portfolio} />
          <Route path="/blog/:slug" Component={BlogDetails} />
          <Route path="/all-blog" Component={AllBlogs} />
          <Route path="/privacy-policy" Component={PrivacyPolicy} />
          <Route path="/thankyou" Component={ThankYou} />
          <Route path="/category/:slug" Component={ProductBottle} />
          <Route path="/product/:slug" Component={Product} />
          <Route
            path="/home/what-makes-us-different"
            Component={WhatMakeDifferent}
          />
        </Routes>

        <div>
          <div className={`fixed-social-icons d-md-flex d-none`}>
            <div>
              <Link
                to="https://www.facebook.com/profile.php?id=61550116506147"
                aria-label="facebook"
                target="_blank"
                className="facebook-icon"
              >
                <i
                  className={`bi bi-facebook ${stickySocial && "text-dark"}`}
                ></i>
              </Link>
              <Link
                aria-label="instagram"
                to="https://www.instagram.com/glamourdecorlunavadodara/?hl=en"
                target="_blank"
                className="instagram-icon"
              >
                <i
                  className={`bi bi-instagram ${stickySocial && "text-dark"}`}
                ></i>
              </Link>
              <Link
                aria-label="youtube"
                to="https://www.youtube.com/channel/UC6g82a3mSpQk8MKiZKbcYmA"
                target="_blank"
                className="youtube-icon"
              >
                <i
                  className={`bi bi-youtube ${stickySocial && "text-dark"}`}
                ></i>
              </Link>
              <Link
                aria-label="linkedin"
                to="https://www.linkedin.com/in-vadodara-34b472289/"
                target="_blank"
                className="linkedin-icon"
              >
                <i
                  className={`bi bi-linkedin ${stickySocial && "text-dark"}`}
                ></i>
              </Link>
            </div>
            <div className={`line mx-4  ${stickySocial && "bg-dark"}`}></div>
            <span className="gradient-line">Follow Us</span>
            {/* <span className={` ${stickySocial && "text-dark"}`}>Follow Us</span> */}
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
