import React, { useEffect, useState } from "react";
import "../assets/css/BlogDetail.css";
import Navbar from "./Home/Navbar";
import Banner2 from "./Home/Banner2";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "./Home/Footer";
import WebService, { urls } from "../Services/WebService";
import Spinner from "./Home/Spinner";
import Swal from "sweetalert";

const BlogDetails = () => {
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState("");
  const [blogData, setBlogData] = useState([]);
  const [latestBlog, setLatestBlog] = useState([]);

  // Form Integration
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [numericValue, setNumericValue] = useState("");

  const slug = useParams();

  useEffect(() => {
    const loadData = async () => {
      const formData = new FormData();
      formData.append("slug", slug.slug);
      try {
        const response = await WebService.postApiCall(urls.BLOGDATA, formData);

        if (response) {
          setLoading(false);
          setBlogData([response.data.data]);
          setLatestBlog(response.data.data.latest_blogs);

          setBanner(response.data.data.banner_img);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };
    loadData();
  }, [slug]);
  const navigate = useNavigate();
  const handleNumericChange = (event) => {
    // Remove non-numeric characters using a regular expression
    const newValue = event.target.value.replace(/[^0-9+]/g, "");
    setNumericValue(newValue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", numericValue);
    formData.append("message", message);
    try {
      const response = await WebService.postApiCall(urls.CONTACT, formData);
      if (response) {
        setLoading(false);
        // Swal Popup
        // Swal({
        //   icon: "success",
        //   text: "Your message send successfully",
        // });
        setName("");
        setEmail("");
        setMessage("");
        setNumericValue("");
        navigate("/thankyou");
      }
    } catch (error) {
      setLoading(false);
      Swal({
        icon: "error",
        text: "Something Went Wrong",
      });
    }
  };
  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        <section>
          <Banner2
            text={"Blog-details"}
            header={"Blog-details"}
            banner={banner}
          />
        </section>
        <section>
          {blogData.map((ob) => {
            const renderFirst15Words = (content) => {
              // const first15Words = content.split(" ").slice(0, 14).join(" ");
              return <span dangerouslySetInnerHTML={{ __html: content }} />;
            };
            return (
              <div key={ob.id}>
                <div className="container-box py-5" id="blog-bg">
                  <div className="py-md-5">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="mb-5 ps-3 pe-xs-3">
                          <img
                            src={ob.img}
                            alt="mainblogimg"
                            className="img-fluid w-100 object-fit-cover"
                            style={{ height: "35rem" }}
                          />
                        </div>
                        <div className="content px-3">
                          <div>
                            <h2 className="mb-3">{ob.title}</h2>
                            <p className="text-muted">
                              {renderFirst15Words(ob.content)}
                            </p>
                          </div>
                          <div>
                            <ul>
                              <li>100% Recycling</li>
                              <li>Made From Natural Materials</li>
                              <li>
                                Almost Zero Rates Of Chemical Interaction{" "}
                              </li>
                              <li>Is Plastic Good For Nothing?</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pe-4">
                        <section className="">
                          <div className="card border-0 mb-3 ">
                            <div className="card-body ">
                              <h4 className="mb-0 ps-2">Recent Blog Posts</h4>
                            </div>
                          </div>
                          {latestBlog.map((ob) => {
                            const created_at = ob.created_at;
                            console.log(ob.slug);
                            return (
                              <div key={ob.id} className="card border-0 mb-3">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-5 d-flex align-items-center">
                                      <Link
                                        to={`/blog/${ob.slug}`}
                                        className="nav-link shadow-sm "
                                      >
                                        <img
                                          src={ob.blog_img}
                                          alt="blog-1"
                                          className="img-fluid w-100 "
                                        />
                                      </Link>
                                    </div>
                                    <div className="col-7">
                                      <p className="mb-1 text-muted">
                                        {created_at.slice(0, 10)}
                                      </p>
                                      <p>
                                        <Link
                                          to={`/blog/${ob.slug}`}
                                          className="nav-link"
                                        >
                                          {ob.title}
                                        </Link>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <div className="card border-0 mb-3 py-3">
                            <div className="card-body">
                              <div className="py-3 ps-2">
                                <p>Contect Us</p>
                              </div>
                              <div>
                                <form onSubmit={handleSubmit}>
                                  <div className="">
                                    <div className="mb-3">
                                      <div className="bd-forms-inputs">
                                        <input
                                          required
                                          placeholder="Type Your Name"
                                          type="text"
                                          value={name}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="mb-3">
                                      <div className="bd-forms-inputs">
                                        <input
                                          required
                                          placeholder="Type Your Email Address"
                                          type="email"
                                          value={email}
                                          onChange={(e) =>
                                            setEmail(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="mb-3">
                                      <div className="bd-forms-inputs">
                                        <input
                                          required
                                          minLength={10}
                                          maxLength={10}
                                          placeholder="Type Your Mobile Number"
                                          type="text"
                                          value={numericValue}
                                          onChange={handleNumericChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="mb-3">
                                      <div className="bd-forms-inputs">
                                        <textarea
                                          required
                                          placeholder="Type Your Message"
                                          type="text"
                                          rows="7"
                                          value={message}
                                          onChange={(e) =>
                                            setMessage(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <button className="submit-contact">
                                      Send Message
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default BlogDetails;
