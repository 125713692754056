import React from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import Banner from "../Home/Banner";
import thankyouimg from "../../assets/Images/thankYou.jpg";
import bannerimg from "../../assets/Images/banner-55.avif";

const ThankYou = () => {
  return (
    <div>
      <Navbar />
      <main>
        <section>
          <div id="thankyou-banner">
            <Banner text={""} header={""} banner={bannerimg} />
          </div>
        </section>

        <section>
          <div className="page-content py-5" style={{ backgroundColor: "lig" }}>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div className="" style={{ maxWidth: "20%" }}>
                <img
                  src={thankyouimg}
                  alt="thankyouimg"
                  className="img-fluid"
                />
              </div>
              <div>
                {/* <h1 className="display-2 fw-medium">Thank You !</h1> */}
              </div>
              <div>
                <p className="paragraph-2 text-center fs-2 fw-medium text-dark">
                  Thank you for Visiting our Website.
                  <br /> You will recieved an email message shortly.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ThankYou;
