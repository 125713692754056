import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import "../../assets/css/Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import CarouselSlider from "./CarouselSlider";
import Slider from "react-slick";
// slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Footer from "./Footer";
import { Link } from "react-router-dom";

import WebService, { urls } from "../../Services/WebService";
import Spinner from "./Spinner";

const HomeMain = () => {
  const [loading, setLoading] = useState(false);
  const [sliderData, setSliderData] = useState([]);
  const [partner, setPartner] = useState([]);
  const [partnerSection, setPartnerSection] = useState([]);
  const [ourServices, setOurServices] = useState([]);
  const [ctaSection, setCtaSection] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [aboutUs, setAboutUs] = useState([]);
  const [ourGallary, setOurGallary] = useState([]);
  const [ourGallary1, setOurGallary1] = useState([]);
  const [ourGallary2, setOurGallary2] = useState([]);
  const [ourGallary3, setOurGallary3] = useState([]);
  const [ourGallary4, setOurGallary4] = useState([]);
  const [ourGallary5, setOurGallary5] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);

  useEffect(() => {
    AOS.init({
      offset: 100, // offset (in px) from the original trigger point
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease-out",
      once: false,
    });
  }, []);
  const sliderBlogRef = useRef();

  const blogsSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const response = await WebService.GetApiCall(urls.HOMEAPI);
        if (response.data.success) {
          const home = response.data.home_page;
          setCompanyInfo(home.company_info);
          setSliderData(home.slider);
          setPartner(home.partner);
          setOurServices(home.ourservice);
          setOurGallary(home.portfolio_image[3]);
          setOurGallary1(home.portfolio_image[2]); // print
          setOurGallary2(home.portfolio_image[1]); // foiling
          setOurGallary3(home.portfolio_image[4]); //froshting
          setOurGallary4(home.portfolio_image[0]); //decal- print
          setOurGallary5(home.portfolio_image[5]); // decal- print
          setCtaSection(home.cta_section);
          setBlogData(home.blog);
          setAboutUs(home.intro_section[0]);
          setPartnerSection(home.partner_section);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error loading data: ", error);
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const renderFirst15Words = (content) => {
    const first15Words = content.split(" ").slice(0, 14).join(" ");
    return <span dangerouslySetInnerHTML={{ __html: first15Words }} />;
  };

  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        {/* Banner section */}
        <section>
          <CarouselSlider sliderData={sliderData} />
        </section>

        <section>
          <div className="py-4 ">
            <div className="container-box">
              <div className="row">
                {companyInfo.map((ob) => {
                  return (
                    <div
                      key={ob.id}
                      className="col-md-4 px-xs-0"
                      data-aos="fade-right"
                      data-aos-delay="100"
                    >
                      <div className="our-works shadow-sm">
                        <i
                          className={
                            ob.id === 2
                              ? `bi bi-person-workspace d-flex
                          align-items-center`
                              : ob.id === 3
                              ? `bi bi-graph-up-arrow d-flex
                          align-items-center`
                              : ob.id === 1
                              ? `bi bi-code-square d-flex align-items-center`
                              : ""
                          }
                        ></i>

                        <div>
                          <p className="h4 text-dark">{ob.title}</p>
                          <p className="mb-1">{ob.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        {/* Our latest AWESOME Services  start */}

        <section>
          <div className="pb-4">
            <div className="container-box py-md-4">
              <div className="d-md-flex justify-content-between align-items-center px-4">
                <div className="top-heading">
                  <span className="title-h2 title-block-lg d-block heading-color">
                    <span>
                      Our latest <br />
                      <b>AWESOME Services </b>
                    </span>
                  </span>
                </div>

                <div className="mt-md-0 mt-5 mt-xs-2">
                  <Link className="style-btn ps-xs-0" to="/coating">
                    <span className="ps-xs-0 mr-xs-1">{" > "}</span>{" "}
                    <div className="d-inline">view all services</div>
                  </Link>
                </div>
              </div>

              <div className="mt-5 mt-xs-0">
                <div
                  className="row our-services justify-content-between h-xs-lservice"
                  // style={{ width: 1409, height: 598 }}
                >
                  {ourServices.map((ourservice, i) => {
                    const styl = {
                      transform: "translateY(50%)",
                    };
                    return (
                      <div
                        key={ourservice.id + i}
                        className={
                          i === 1
                            ? "col-md-4 px-xs-0  middle-service-card"
                            : "px-xs-0 col-md-4 "
                        }
                        style={i === 1 ? styl : null}
                        data-aos="fade-right"
                        data-aos-delay="100"
                      >
                        <div className="service-card-1 mt-4">
                          <img
                            src={ourservice.img}
                            className="service-img"
                            alt=""
                          />
                          <div className="pt-md-4 service-data">
                            <p className="h4">{ourservice.heading} Service</p>
                            <Link
                              to={`/${
                                i === 4
                                  ? "decal-print"
                                  : ourservice.heading.toLowerCase()
                              }`}
                            >
                              {" "}
                              Know More{" "}
                              <i className="bi bi-chevron-double-right arrow-animation"></i>{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Our latest AWESOME Services end*/}

        {/* About Us Section start */}
        <section>
          <div className="pb-5 mb-2 mt-xs-0">
            <div className="container-box py-md-5 px-xs-0">
              <div className="row">
                <div className="col-md-6 col-lg-6 px-xs-0 pr-0">
                  <img
                    src={aboutUs.img}
                    className="w-100 h-100"
                    alt="unsplash"
                    style={{ objectFit: "cover" }}
                    data-aos="fade-right"
                    data-aos-delay="100"
                  />
                </div>

                <div
                  className="col-md-6 col-lg-6 py-4"
                  style={{ backgroundColor: "#efefef" }}
                >
                  <div className=" p-3 px-xs-0">
                    <h2 className="text-uppercase fs-3 fw-medium mb-3">
                      About Us
                    </h2>

                    <h2 className="head-2 mb-4">
                      {aboutUs.intro_section_title}
                    </h2>

                    <p className="paragraph-2">{aboutUs.intro_section_text}</p>

                    <div className="mt-4">
                      <p className="list-item d-flex align-items-center paragraph p-2 px-3 bg-white">
                        <i className="bi bi-check fs-4 me-3 text-success"></i>{" "}
                        {aboutUs.intro_section_point1}
                      </p>

                      <p className="list-item d-flex align-items-center paragraph p-2 px-3 bg-white">
                        <i className="bi bi-check fs-4 me-3 text-success"></i>{" "}
                        {aboutUs.intro_section_point2}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* About Us Section end */}

        {/* Our Gallery Section Start */}

        <section className="bg-light-gray">
          <div className="py-5">
            <div className="container-box py-md-4">
              <div className="d-md-flex justify-content-between align-items-end">
                <div className="top-heading">
                  <p className="description d-inline-block p-relative circle-before ">
                    <span>OUR PORTFOLIO</span>
                  </p>
                  <span className="title-h2 title-block-lg d-block heading-color">
                    <span>Our Gallery</span>
                  </span>
                </div>
              </div>

              <div className="mt-5">
                <div className="row our-services">
                  <div
                    className="col-md-3 px-xs-0"
                    data-aos="zoom-in"
                    data-aos-delay="104"
                  >
                    <div className="service-card-1 h-100">
                      <img
                        src={ourGallary.img}
                        alt="No Img"
                        className="w-100 h-100"
                        style={{ objectFit: "cover" }}
                      />
                      <div className=" service-data">
                        <p className="h4">{ourGallary.title} </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 px-xs-0">
                    <div className="row h-100">
                      <div
                        className="col-sm-6 px-xs-0 mt-xs-4"
                        data-aos="zoom-in"
                        data-aos-delay="103"
                      >
                        <div className="service-card-1 h-100">
                          <img
                            src={ourGallary1.img}
                            alt="No Img"
                            className="w-100 h-100"
                          />
                          <div className=" service-data">
                            <p className="h4">{ourGallary1.title}</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-sm-6 px-xs-0 mt-xs-4"
                        data-aos="zoom-in"
                        data-aos-delay="102"
                      >
                        <div className="service-card-1 h-100">
                          <img
                            src={ourGallary2.img}
                            alt="No Img"
                            className="w-100 h-100"
                            style={{ objectFit: "cover" }}
                          />
                          <div className=" service-data">
                            <p className="h4">{ourGallary2.title}</p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-sm-4 px-xs-0 mt-4"
                        data-aos="zoom-in"
                        data-aos-delay="100"
                      >
                        <div className="service-card-1 h-100">
                          <img
                            src={ourGallary3.img}
                            alt="No Img"
                            className="w-100 h-100 object-fit-cover"
                          />
                          <div className=" service-data">
                            <p className="h4">{ourGallary3.title} </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-sm-8 px-xs-0 mt-4"
                        data-aos="zoom-in"
                        data-aos-delay="100"
                      >
                        <div className="service-card-1 h-100">
                          <img
                            src={ourGallary4.img}
                            alt="No Img"
                            className="w-100 h-100 object-fit-cover"
                            height="250"
                          />
                          <div className=" service-data">
                            <p className="h4">{ourGallary4.title}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-3 mt-xs-4 px-xs-0"
                    data-aos="zoom-in"
                    data-aos-delay="104"
                  >
                    <div className="service-card-1 h-100">
                      <img
                        src={ourGallary5.img}
                        alt="No Img"
                        className="w-100 h-100 object-fit-cover"
                      />
                      <div className=" service-data">
                        <p className="h4">{ourGallary5.title}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Our Gallery Section end */}

        {/* CTA Section */}

        <section>
          <div
            className="py-5 contact-bg"
            style={{ backgroundImage: ` url(${ctaSection.cta_background})` }}
          >
            <div className="container-box py-md-5">
              <div key={ctaSection.id} className="row">
                <div className="col-md-8 ps-md-5">
                  <div className="cta-section">
                    <h2>{ctaSection.cta_section_text}</h2>

                    <p className="paragraph mb-5">
                      {ctaSection.cta_section_subtitle}
                    </p>

                    <div>
                      <Link className="style-btn style-btn-3" to="/contact-us">
                        <span>{" > "}</span>{" "}
                        <div className="d-inline">contact with us</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* blogs */}
        <section>
          <div className="py-5 ">
            <div className="container-box py-md-4 ">
              <div className="d-md-flex justify-content-between align-items-end">
                <div className="top-heading ps-4">
                  <p className="description d-inline-block p-relative circle-before ">
                    <span>OUR BLOGS</span>
                  </p>
                  <span className="title-h2 title-block-lg d-block heading-color">
                    <span className="d-xs-block">
                      <b>Blog Posts </b>
                    </span>
                    <span className="all-blog-button">
                      <Link
                        className="style-btn col-5  bd-blog-btn fs-5"
                        style={{ cursor: "pointer" }}
                        to="/all-blog"
                      >
                        <span className="me-2">{" < "}</span>
                        <div className="d-inline fs-6 "> All Blogs</div>
                      </Link>
                    </span>
                  </span>
                </div>

                <div
                  className="mt-md-0 mt-5 ps-xs-4 row col-4 mobile-width"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingRight: "1.5%",
                  }}
                >
                  <Link
                    className="style-btn style-btn-xs col-5 me-3 bd-blog-btn"
                    onClick={() => sliderBlogRef.current.slickPrev()}
                    style={{ cursor: "pointer" }}
                  >
                    <span>{" < "}</span>
                    <div className="d-inline fs-6 mobile-font"> Previous</div>
                  </Link>
                  <Link
                    style={{ cursor: "pointer" }}
                    className="style-btn style-btn-xs col-5 bd-blog-btn"
                    onClick={() => sliderBlogRef.current.slickNext()}
                  >
                    <span>{" > "}</span>
                    <div className="d-inline fs-6 mobile-font"> Next</div>
                  </Link>
                </div>
              </div>

              <div className="mt-4">
                <div className="row our-services">
                  <Slider
                    ref={sliderBlogRef}
                    {...blogsSettings}
                    className="px-xs-0"
                  >
                    {blogData.map((ob) => {
                      const dateofcontent = ob.created_at;
                      const first15Characters = dateofcontent.slice(0, 10);
                      return (
                        <div className="col-md-5" key={ob.id}>
                          <div className="service-card blog-card mx-xs-0">
                            <div>
                              <img src={ob.main_img} className="w-100" alt="" />
                              <div className="hover-box">
                                {first15Characters}
                              </div>
                            </div>

                            <div className="service-card-content mt-4">
                              <p>{ob.title}</p>
                              <p className="text-muted">
                                {renderFirst15Words(ob.content)}
                              </p>

                              <Link to={`/blog/${ob.slug}`}>
                                {" "}
                                Read Full Blog{" "}
                                <i className="bi bi-chevron-double-right arrow-animation"></i>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* partners */}
        <section>
          <div className="py-5 bg-light-gray">
            <div className="py-md-5 container-box">
              <div className="row align-items-center">
                {partnerSection.map((ob, i) => {
                  return (
                    <div key={i + 2} className="col-md-3">
                      <span
                        className="bordered-text d-inline-block"
                        style={{ transform: "scaleY(1.25)" }}
                      >
                        {ob.partner_section_text}
                      </span>
                      <p className="mt-5 lh-sm px-3 fs-4 fw-medium">
                        {ob.partner_section_title}
                      </p>
                    </div>
                  );
                })}

                <div className="col-md-9 mt-md-0 mt-5">
                  <div className="row p-logos">
                    {partner.map((ob) => {
                      return (
                        <div key={ob.id} className="col-md-3 col-6 border-2">
                          <img src={ob.img} alt="ourpartner" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HomeMain;
