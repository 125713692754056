import React, { useRef } from "react";
import Navbar from "./Navbar";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Banner2 from "./Banner2";
import WebService, { urls } from "../../Services/WebService";
import Spinner from "./Spinner";
import { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert";

const Career = () => {
  const [loading, setLoading] = useState(true);
  const [careerData, setCareerData] = useState([]);

  const [banner, setBanner] = useState("");
  const [number, setNumber] = useState("");

  // Form Integration
  const [numericValue, setNumericValue] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [job, setJob] = useState("");
  const [file, setFile] = useState("");

  const handleNumericChange = (event) => {
    // Remove non-numeric characters using a regular expression
    const newValue = event.target.value.replace(/[^0-9+]/g, "");
    setNumericValue(newValue);
  };

  const applyjob = useRef(null);
  const scrollToBottom = () => {
    const targetDiv = applyjob.current;
    targetDiv.scrollIntoView({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const loadData = async () => {
      const response = await WebService.GetApiCall(urls.CAREER);
      if (response) {
        setLoading(false);

        setCareerData(response.data.career);
        setBanner(response.data.career.banner);
        setNumber(response.data.career.phone_numbers);
      }
    };
    loadData();
  }, []);
  const danghtml = (content) => {
    return <span dangerouslySetInnerHTML={{ __html: content }} />;
  };

  const navigate = useNavigate();
  // form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("email", email);
    formData.append("phone", numericValue);
    formData.append("job", job);
    formData.append("attachment", file);
    try {
      const response = await WebService.postApiCall(urls.CAREERMAIL, formData);
      if (response) {
        setLoading(false);
        // Swal Popup
        // Swal({
        //   icon: "success",
        //   text: "Your message send successfully",
        // });
        setFname("");
        setLname("");
        setEmail("");
        setNumericValue("");
        setJob("");
        setFile(null);
        navigate("/thankyou");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      Swal({
        icon: "error",
        text: "Something Went Wrong",
      });
    }
  };
  return (
    <div>
      {loading && <Spinner />}
      <Navbar />
      <main>
        <section>
          <Banner2 text={"We Are Hiring"} header={"Career"} banner={banner} />
        </section>

        <section>
          <div className="pt-5 bg-light-gray">
            <div className="container-box py-md-5">
              <div className="px-md-5">
                <div className="row pt-4 justify-content-between ">
                  <div className="col-md-6 pe-md-4">
                    <div className="heading-top">
                      <h3> {careerData.career_text}</h3>
                    </div>
                    <p className="paragraph-2">
                      {danghtml(careerData.career_description)}
                    </p>

                    <div className="mt-md-0 mt-5 mb-4 pt-4 aplybtn">
                      <Link className="style-btn" onClick={scrollToBottom}>
                        <span>{" > "}</span>{" "}
                        <div className="d-inline">Apply Now</div>
                      </Link>
                    </div>
                  </div>

                  <div className="col-md-6 position-relative ps-md-4">
                    <div className="col-md- ">
                      <div className="service-card work-card service-slider mx-0">
                        <div>
                          <img
                            src={careerData.career_bg}
                            className=""
                            alt=""
                            style={{
                              padding: "10px",
                              border: "1px solid #2828281a",
                              width: "100%",
                              height: "420px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section ref={applyjob}>
          <div
            className="py-5  contact-bg"
            style={{
              backgroundImage: `url(${careerData.background})`,
            }}
          >
            <div className="container-box py-md-5">
              <div className="row justify-space-between">
                <div className="col-md-7 ps-md-5">
                  <div className="cta-section">
                    <h2>{careerData.career_text2}</h2>
                    <p>{careerData.career_description2}</p>
                    <div className="pt-5 ">
                      <Link className="style-btn style-btn-3 webview">
                        <span style={{ paddingLeft: "18px" }}>
                          <i className="bi bi-telephone "></i>
                        </span>{" "}
                        <div className="d-inline">
                          Call Us : {careerData.phone_numbers}
                        </div>
                      </Link>
                      <div className="cta-long-btn">
                        <div className="mb-5">
                          <Link className="style-btn style-btn-3">
                            <span style={{ paddingLeft: "18px" }}>
                              <i className="bi bi-telephone "></i>
                            </span>{" "}
                            <div className="d-inline">
                              Call Us : {number.slice(0, 14)}
                            </div>
                          </Link>
                        </div>
                        <div className="mb-5">
                          <Link className="style-btn style-btn-3 ">
                            <span style={{ paddingLeft: "18px" }}>
                              <i className="bi bi-telephone "></i>
                            </span>{" "}
                            <div className="d-inline">
                              Call Us : {number.slice(15)}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 ">
                  {/* Form  */}

                  <form className="bd-cta-form" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <div className="bd-forms-inputs">
                          <input
                            required
                            placeholder="Type Your First Name"
                            type="text"
                            value={fname}
                            onChange={(e) => setFname(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="bd-forms-inputs">
                          <input
                            required
                            placeholder="Type Your Last Name"
                            type="text"
                            value={lname}
                            onChange={(e) => setLname(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="bd-forms-inputs">
                          <input
                            required
                            placeholder="Type Your Email Address"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="bd-forms-inputs">
                          <input
                            required
                            placeholder="Type Your Mobile Number"
                            type="text"
                            value={numericValue}
                            onChange={handleNumericChange}
                            maxLength={10}
                            minLength={10}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="bd-forms-inputs">
                          <input
                            required
                            placeholder="Type Your Job Title"
                            type="text"
                            value={job}
                            onChange={(e) => setJob(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="bd-forms-inputs">
                          <input
                            required
                            placeholder="Type Your Job Title"
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <button className="submit-contact">Apply Now</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Career;
